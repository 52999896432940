<template>
  <div class="fireworks">
  </div>
</template>
<script>

export default {
  name: "Fireworks",
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>